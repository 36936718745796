<template>
  <div class="wrap">
    <Loading v-show="$store.state.isLoading"/>
    <van-list v-show="!$store.state.isLoading">
      <!-- <van-cell v-for="(item,id) in list" :key="id" :title="item.title" is-link /> -->
      <van-col v-show="!isEmpty" span="22" offset="1" v-for="(item,id) in list" :key="id" style="margin-top:10px;border-radius:5px;">
        <van-cell is-link :center="true" @click="jumpTo(item)">
          <template #title>
            <span class="custom-title">{{item.wx_type | wxType}}</span>
            <span class="custom-date">{{item.apply_time}}</span>
            <van-tag v-if="item.pay_method" color="#FFECE3" text-color="#FF6010" class="custom-status">{{item.pay_method | payMethod}}</van-tag>
            <van-tag v-else color="#FFECE3" text-color="#FF6010" class="custom-status">{{item.approval_status | approvalStatus}}</van-tag>
          </template>
        </van-cell>
      </van-col>
      <van-empty v-show='isEmpty'
        class="custom-image"
        :image="emptyImg"
        description="暂无数据"
      />
    </van-list>
  </div>
</template>
<script>
import {myApply,getOpenId,querytype} from '@/server'
import { Dialog,Toast } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl,getUrlParam} from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      accessToken:this.$store.state.accessToken,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      list: [],
      isEmpty:false,
      emptyImg:require('@/assets/img/empty.png'),
      source:getUrlParam('flag')
    };
  },
  filters: {
    payMethod(s) {
      if(s == '0' || s == '1'){
        return '已支付';
      }else if(s == '2'){
        return '确认已付款';
      }else{
        return '--';
      }
    },
    //0审核中，1已通过，2退回
    approvalStatus(s) {
      if(s == '0' || s == '3'){
        return '审核中';
      }else if(s == '1'){
        return '审核通过';
      }else if(s == '2'){
        return '审核拒绝';
      }
    },
    //settle 提前结清,companypay 对公还款, datechange 还款日变更, basechange 手机号变更,cardchange 扣款卡变更
    wxType(s) {
      if(s == 'settle'){
        return '提前结清';
      }else if(s == 'companypay'){
        return '对公还款';
      }else if(s == 'datechange'){
        return '租后变更-还款日变更';
      }else if(s == 'basechange'){
        return '租后变更-手机号变更';
      }else if(s == 'cardchange'){
        return '租后变更-扣款卡变更';
      }else if(s == 'frtmoney'){
        return '前置保证金付款申请';
      }
    }
  },
  mounted(){
    sessionStorage.removeItem("origin");
    sessionStorage.removeItem("source");
    sessionStorage.removeItem("payInfoId");
    // sessionStorage.setItem('key', 'application2');
    // let that = this;
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // if(sessionStorage.getItem('key') == 'application2') {
    //     that.$router.push({ path: '/personalCenter' });
    //   }
    // }, false);
  },
  created(){
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          this.cardId = res.card_id;
          this.accessToken = res.access_token;
          this.openId = res.openid;
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/application' });
          }else{
            this.getDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/application');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            this.cardId = res.card_id;
            this.accessToken = res.access_token;
            this.openId = res.openid;
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/application' });
            }else{
              this.getDetail();
            } 
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }  
  },
  methods: {
    getDetail(){
      if(!this.source){
        sessionStorage.setItem('menu_name','我的申请-消息提醒')
        const querytypeparams = {
          card_id:this.cardId,
          function_type:'menu',
          menu_name:'我的申请-消息提醒'
        }
        querytype(querytypeparams);
      }
      const params = {
        openid:this.openId,
        access_token:this.accessToken,
        card_id:this.cardId
      }
    myApply(params).then(res=>{
      this.list = res.filter(item => item.business_type!="01");
      // if(res.wx_code == '0'){
        if(this.list.length == 0){
          this.isEmpty = true;
        }else{
          this.isEmpty = false;
          res.map(item => {
            if(item.wx_type == 'settle'){
              item.pathUrl = '/earlySettlementDetail';
            }else if(item.wx_type == 'companypay'){
              item.pathUrl = '/corporateRepaymentDetail'
            }else if(item.wx_type == 'datechange'){
              item.pathUrl = '/modifyRepaymentDetail'
            }else if(item.wx_type == 'basechange'){
              item.pathUrl = '/modifyInfoDetail'
            }else if(item.wx_type == 'cardchange'){
              item.pathUrl = '/modifyDebitCardDetail'
            }else if(item.wx_type == 'frtmoney'){
              item.pathUrl = '/advanceDeposit/paymentDetail?applyId='+item.apply_id+'&payMethod='+item.pay_method
            }
          });
        } 
      // }else{
      //   Toast(res.msg);
      // }
      
    })
  },
    jumpTo(item){
      this.$store.commit('dataDetailList', item.detail);
      // :to="{ path: item.pathUrl, query: { dataDetail: item.detail }}"
      this.$router.push({ path: item.pathUrl });
      // window.open(item.pathUrl)
    },
  },
};
</script>
<style scoped>
.wrap{
    /* padding: 20px 0px;  */
    overflow: hidden;
    min-height: 100vh !important;
    background: #F8F8F8; 
  }
  .custom-title {
    display:block;
    /* vertical-align: middle; */
  }
  .custom-date {
    display:block;
    color: #999999;
    font-size: 12px;
  }
  .custom-status{
    font-size: 11px;
  }
</style>